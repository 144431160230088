import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Layout, RiskInfo, RiskAds } from "../../components";
import { useTranslation } from "react-i18next";

import {
  regionMapping,
  provinceMapping,
  districtMapping,
  tambonMapping,
} from "../../libs/translations/mappings";

const Risk = () => {
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState({});
  const { i18n } = useTranslation();

  const mapValuesBasedOnLocale = (params) => {
    const locale = i18n.language;

    if (locale === "en") {
      return {
        ...params,
        region: regionMapping[params.region] || params.region,
        province: provinceMapping[params.province] || params.province,
        district: districtMapping[params.district] || params.district,
        tambon: tambonMapping[params.tambon] || params.tambon,
      };
    }
    return params;
  };

  useEffect(() => {
    const initialParams = Object.fromEntries([...searchParams]);
    const localizedParams = mapValuesBasedOnLocale(initialParams);
    setParams(localizedParams);
  }, [searchParams, i18n.language]);

  return (
    <Layout>
      <RiskInfo
        province={params?.province}
        district={params?.district}
        tambon={params?.tambon}
        date={params?.date}
        time={params?.time}
        isPlanning={true}
      />
      <RiskAds />
    </Layout>
  );
};

export default Risk;
