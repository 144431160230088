import React from "react";
import YouTube from "react-youtube";
import {
  MDBContainer,
  MDBAccordion,
  MDBAccordionItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { Layout } from "../../components";
import { useTranslation } from "react-i18next";

const videoSections = [
  {
    key: "video_help_section_computer",
    videos: [
      { videoId: "asGG3w6yA58", key: "video_1" },
      { videoId: "bqtqu3haVLI", key: "video_2" },
      { videoId: "Ylc3jZ-ihfo", key: "video_3" },
      { videoId: "4wgvBuFRteg", key: "video_4" },
      { videoId: "Yj63NaKl-Zo", key: "video_5" },
      { videoId: "UKZP0lspZfI", key: "video_6" },
      { videoId: "hRfBGcFpxIY", key: "video_7" },
      { videoId: "Tl2tJrx7nAw", key: "video_8" },
      { videoId: "N8x93UNJDuU", key: "video_9" },
      { videoId: "4gqhE2KlL-I", key: "video_10" },
    ],
  },
  {
    key: "video_help_section_mobile",
    videos: [
      { videoId: "DviA6oTfI_Q", key: "video_1_mobile" },
      { videoId: "gvhyPPoRGWU", key: "video_2_mobile" },
      { videoId: "qeQRc18UEvw", key: "video_3_mobile" },
      { videoId: "X1xHbB4TwE4", key: "video_4_mobile" },
      { videoId: "etg_rnKAsHc", key: "video_5_mobile" },
      { videoId: "r4k6h1IE3vs", key: "video_6_mobile" },
      { videoId: "bmYK6qDkXJg", key: "video_7_mobile" },
      { videoId: "KRsSUwoDu-E", key: "video_8_mobile" },
      { videoId: "NFZHAigYDOI", key: "video_9_mobile" },
      { videoId: "sFzKYwBEEXg", key: "video_10_mobile" },
      { videoId: "vZ1E-kFz1Sc", key: "video_11_mobile" },
    ],
  },
];

const VideoHelp = () => {
  const { t } = useTranslation();

  const youtubeOptions = {
    width: "100%",
    height: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <Layout>
      <MDBContainer>
        <h3 className="text-uppercase text-center mb-4 mt-5 text-xl fw-bold">
          {t("video_help_title")}
        </h3>

        <MDBAccordion flush>
          {videoSections.map((section, index) => (
            <MDBAccordionItem
              collapseId={`accordion-item-${index}`}
              headerTitle={
                <span className="text-lg font-bold">{t(section.key)}</span>
              }
              key={index}
              className="bg-transparent"
            >
              <MDBCardBody className="bg-transparent">
                <MDBRow>
                  {section.videos.map((video, videoIndex) => (
                    <MDBCol
                      key={videoIndex}
                      lg="4"
                      md="6"
                      sm="12"
                      className="mb-4"
                    >
                      <h5 className="mb-2 text-center text-sm">
                        {t(video.key)}
                      </h5>
                      <MDBCard className="overflow-hidden shadow-none bg-transparent p-0 m-0">
                        <div className="aspect-w-4 aspect-h-3 w-full relative">
                          <YouTube
                            videoId={video.videoId}
                            opts={youtubeOptions}
                            className="absolute inset-0 w-full h-full"
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                  ))}
                </MDBRow>
              </MDBCardBody>
            </MDBAccordionItem>
          ))}
        </MDBAccordion>
      </MDBContainer>
    </Layout>
  );
};

export default VideoHelp;
