import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiHelper } from "../../libs/api";
import {
  DEFAULT_ERROR_MESSAGE,
  USE_PLACE_API,
  USE_GEO_API,
  GOOGLE_MAPS_API_KEY,
  USE_GMAP_SDK,
} from "../../libs/constants";
import { ModalAlert, Layout } from "../../components";
import CustomFileInput from "../../components/CustomFileInput";
import CustomVideoInput from "../../components/CustomVideoInput";
import CustomAudioInput from "../../components/CustomAudioInput";
import { MDBBtn, MDBContainer, MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import Map from "../../components/Map";
import { Loader } from "@googlemaps/js-api-loader";
import { useTranslation } from "react-i18next";

const AddEvent = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [haveCustomType, setHaveCustomType] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleReverseSearch = async (latitude, longitude) => {
    if (USE_GEO_API && GOOGLE_MAPS_API_KEY) {
      try {
        const response_GeoReverse = await ApiHelper.postGeoReverse({
          latitude: latitude,
          longitude: longitude,
        });
        return response_GeoReverse.data?.formatted_address || "";
      } catch (error) {
        console.error("Error with reverse geocoding:", error);
        return "";
      }
    } else {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();
      return data.display_name || "";
    }
  };

  const handleSearch = async () => {
    if (!inputValue) return;
    if (USE_PLACE_API && GOOGLE_MAPS_API_KEY) {
      if (!selectedPosition) return;
      const response = await ApiHelper.postMapFindPlace({
        input: inputValue,
      });
      let Place_Data_Array = response?.data?.predictions || [];
      setSearchResults(Place_Data_Array);
    } else {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${inputValue}`
      );
      const data = await response.json();
      setSearchResults(data);
    }
  };

  const handleSelectLocation = async (placeIdOrLat, lonOrDisplayName) => {
    if (USE_PLACE_API && GOOGLE_MAPS_API_KEY) {
      const response = await ApiHelper.postMapPlaceID({
        input: placeIdOrLat,
      });
      const { latitude, longitude } = response?.data;
      setSelectedPosition([latitude, longitude]);
      setSearchResults([]);
      setFormValue({ ...formValue, locationSearch: lonOrDisplayName });
      setInputValue(lonOrDisplayName);
    } else {
      setSelectedPosition([placeIdOrLat, lonOrDisplayName]);
      setSearchResults([]);
      setFormValue({ ...formValue, locationSearch: lonOrDisplayName });
      setInputValue(lonOrDisplayName);
    }
  };

  const uploadFile = async (file) => {
    const form = new FormData();
    form.append("file", file);
    const { success, data } = await ApiHelper.uploadFile(form);
    if (success) return data.id;
    return null;
  };

  const [formValue, setFormValue] = useState({
    locationSearch: "",
    locationName: "",
    title: "",
    types: [],
    image: null,
    video: null,
    audioTitle: null,
    audioDescribe: null,
    audioOther: null,
    description: "",
    confirm: false,
    custom_type: "",
  });

  const [modalValue, setModalValue] = useState({
    modalTitle: "",
    modalMessage: "",
    showModal: false,
    onModalClose: () => {},
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      types,
      title,
      locationName,
      image,
      video,
      description,
      confirm,
      custom_type,
      audioTitle,
      audioDescribe,
      audioOther,
    } = formValue;
    if (
      selectedPosition &&
      locationName &&
      types.length > 0 &&
      confirm &&
      !(haveCustomType && !custom_type)
    ) {
      const imageId = image ? await uploadFile(image) : null;
      const videoId = video ? await uploadFile(video) : null;
      const audioTitleId = audioTitle ? await uploadFile(audioTitle) : null;
      const audioDescribeId = audioDescribe
        ? await uploadFile(audioDescribe)
        : null;
      const audioOtherId = audioOther ? await uploadFile(audioOther) : null;

      setModalValue({
        modalTitle: t("add_event_processing"),
        modalMessage: t("add_event_processing_message"),
        showModal: true,
        onModalClose: () => {
          setModalValue({ ...modalValue, showModal: false });
        },
      });

      const { success } = await ApiHelper.addEvent({
        location_title: locationName,
        title: title,
        type: types,
        image: imageId,
        video: videoId,
        audio_title: audioTitleId,
        audio_description: audioDescribeId,
        audio_other: audioOtherId,
        description,
        location: {
          coordinates: [
            parseFloat(selectedPosition[1]),
            parseFloat(selectedPosition[0]),
          ],
          type: "Point",
        },
        custom_type: types.includes("custom") ? custom_type : null,
      });
      if (success) {
        setModalValue({
          modalTitle: t("add_event_success"),
          modalMessage: t("add_event_success_message"),
          showModal: true,
          onModalClose: () => {
            setModalValue({ ...modalValue, showModal: false });
            navigate("/");
          },
        });
      } else {
        setModalValue({
          modalTitle: t("add_event_error"),
          modalMessage: t("add_event_error_message"),
          showModal: true,
          onModalClose: () => {
            setModalValue({ ...modalValue, showModal: false });
          },
        });
      }
    } else {
      setModalValue({
        modalTitle: t("add_event_error"),
        modalMessage: t("add_event_incomplete_form"),
        showModal: true,
        onModalClose: () => {
          setModalValue({ ...modalValue, showModal: false });
        },
      });
    }
  };

  const handleTypeChange = (e) => {
    const { value, checked } = e.target;
    let newTypes = [...formValue.types];

    if (checked) {
      newTypes.push(value);
    } else {
      newTypes = newTypes.filter((type) => type !== value);
    }

    setHaveCustomType(newTypes.includes("custom"));
    setFormValue({ ...formValue, types: newTypes });
  };

  const updateToUserPosition = (position) => {
    const latitude = position?.coords?.latitude;
    const longitude = position?.coords?.longitude;
    if (latitude && longitude) {
      setSelectedPosition([latitude, longitude]);
    }
  };

  const handleLocationError = (error) => {
    const errorMessage = (error) => {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          return t("add_event_permission_denied");
        case error.POSITION_UNAVAILABLE:
          return t("add_event_position_unavailable");
        case error.TIMEOUT:
          return t("add_event_timeout");
        default:
          return t("add_event_unknown_error");
      }
    };
    setModalValue({
      modalTitle: t("add_event_error"),
      modalMessage: errorMessage(error),
      showModal: true,
      onModalClose: () => {
        setModalValue({ ...modalValue, showModal: false });
        navigate("/add-event");
      },
    });
  };

  const getMyCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        updateToUserPosition,
        handleLocationError
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    if (USE_GMAP_SDK && GOOGLE_MAPS_API_KEY) {
      const loader = new Loader({
        apiKey: GOOGLE_MAPS_API_KEY,
        version: "weekly",
        libraries: ["places"],
      });
      loader.load().then(() => {
        console.log("Google Maps loaded");
        getMyCurrentLocation();
      });
    }
  }, [USE_GMAP_SDK]);

  useEffect(() => {
    const updateLocation = async () => {
      try {
        const displayName = await handleReverseSearch(
          selectedPosition[0],
          selectedPosition[1]
        );
        setFormValue((prevFormValue) => ({
          ...prevFormValue,
          locationSearch: displayName,
        }));
      } catch (error) {
        console.error("Error in reverse search:", error);
      }
    };
    if (selectedPosition) {
      updateLocation();
    }
  }, [selectedPosition]);

  return (
    <Layout>
      <MDBContainer
        fluid
        className="d-flex align-items-center justify-content-center min-vh-100"
        style={{ background: "linear-gradient(white, #84BBFF)" }}
      >
        <MDBCard
          className="m-5"
          style={{
            maxWidth: "1300px",
            minWidth: "350px",
            borderRadius: "20px",
          }}
        >
          <MDBCardBody className="px-4 px-md-5">
            <h2
              className="text-uppercase text-center mb-5 fw-bold"
              // style={{ color: "black", fontFamily: "Kanit-SemiBold" }}
            >
              {t("add_event_title")}
            </h2>
            <div className="d-flex flex-column flex-lg-row">
              <div className="w-100 w-lg-50">
                <div
                  className="alert alert-info mb-4"
                  role="alert"
                  style={{ color: "#2D6A74", fontFamily: "Kanit-Regular" }}
                >
                  {t("add_event_note")}
                  <br />
                  <div style={{ color: "red" }}>{t("add_event_note_2")}</div>
                </div>
                <div className="mb-4">
                  <p
                    style={{
                      marginBottom: "0",
                      fontSize: "1.2em",
                      fontFamily: "Kanit-Regular",
                    }}
                  >
                    {t("add_event_location_label")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <p
                    style={{
                      marginBottom: "0",
                      fontFamily: "Kanit-Regular",
                    }}
                  >
                    {t("add_event_location_description")}
                  </p>
                </div>
                <div
                  className="input-group-container"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <div className="input-group input-group-lg">
                    <div className="input-group input-group-lg">
                      <input
                        placeholder={t("add_event_location_placeholder")}
                        size="lg"
                        id="input-location"
                        type="text"
                        name="location"
                        value={inputValue || ""}
                        onChange={(e) => setInputValue(e.target.value)}
                        required
                        className="w-100 p-2 text-base border border-gray-300 rounded"
                        autoComplete="off"
                      />
                      <button
                        onClick={handleSearch}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          background: "none",
                          border: "none",
                          padding: 0,
                        }}
                      >
                        <img
                          src="search.png"
                          alt="Search"
                          style={{
                            height: "30px",
                          }}
                        />
                      </button>
                    </div>
                  </div>
                  <span
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={getMyCurrentLocation}
                  >
                    <img
                      src="current-location-icon.png"
                      alt="pin-location"
                      style={{
                        height: "30px",
                      }}
                    />
                  </span>
                </div>
                <div style={{ position: "relative" }}>
                  {searchResults.length > 0 && (
                    <div
                      className="dropdown-menu show"
                      style={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        zIndex: 10000,
                        fontFamily: "Kanit-Regular",
                      }}
                    >
                      {searchResults.map((result) => (
                        <button
                          key={
                            USE_PLACE_API ? result.place_id : result.place_id
                          }
                          className="dropdown-item"
                          onClick={() =>
                            USE_PLACE_API
                              ? handleSelectLocation(
                                  result.place_id,
                                  result.description
                                )
                              : handleSelectLocation(
                                  result.lat,
                                  result.lon,
                                  result.display_name
                                )
                          }
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {USE_PLACE_API
                            ? result.description
                            : result.display_name}
                        </button>
                      ))}
                    </div>
                  )}
                </div>

                <div
                  style={{
                    marginBottom: 20,
                    marginTop: 10,
                  }}
                >
                  <Map
                    position={selectedPosition}
                    setPosition={setSelectedPosition}
                    locale={i18n.language}
                  />
                </div>
              </div>
              <div className="w-100 w-lg-50 ps-lg-4 ps-0">
                <p
                  style={{
                    marginBottom: "0",
                    fontSize: "1.2em",
                    fontFamily: "Kanit-Regular",
                  }}
                >
                  {t("add_event_location_name")}
                  <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="text"
                  value={formValue.locationName}
                  onChange={(e) =>
                    setFormValue({ ...formValue, locationName: e.target.value })
                  }
                  className="w-100 py-1 px-2 text-base border border-gray-300 rounded"
                  placeholder={t("add_event_location_name_placeholder")}
                />
                <p
                  style={{
                    marginBottom: "0",
                    fontSize: "1.2em",
                    fontFamily: "Kanit-Regular",
                  }}
                >
                  {t("add_event_title_label")}
                  <span style={{ color: "red" }}>*</span>
                </p>
                <div>
                  <CustomAudioInput
                    text={formValue.title}
                    setText={(e) =>
                      setFormValue({ ...formValue, title: e.target.value })
                    }
                    placeholder={t("add_event_title_placeholder")}
                    setAudio={(audio) =>
                      setFormValue({ ...formValue, audioTitle: audio })
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <p
                    style={{
                      marginBottom: "0",
                      fontSize: "1.2em",
                      fontFamily: "Kanit-Regular",
                    }}
                  >
                    {t("add_event_types_label")}
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className="row w-100 m-0">
                    {[
                      {
                        value: "life",
                        label: t("add_event_type_life"),
                      },
                      {
                        value: "sex",
                        label: t("add_event_type_sex"),
                      },
                      {
                        value: "drug",
                        label: t("add_event_type_drug"),
                      },
                      {
                        value: "property",
                        label: t("add_event_type_property"),
                      },
                    ].map(({ value, label }) => (
                      <label
                        key={value}
                        className="form-check-label p-0 d-flex align-items-center col-6"
                        style={{ fontFamily: "Kanit-Regular" }}
                      >
                        <input
                          type="checkbox"
                          value={value}
                          onChange={handleTypeChange}
                          checked={formValue.types.includes(value)}
                          className="form-check-input me-2"
                        />
                        {label}
                      </label>
                    ))}
                  </div>
                  <div
                    className="mb-4 w-100"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <label
                      className="d-flex form-check-label align-items-center"
                      style={{
                        fontFamily: "Kanit-Regular",
                      }}
                    >
                      <input
                        type="checkbox"
                        value="custom"
                        className="form-check-input me-2"
                        onChange={handleTypeChange}
                        checked={formValue.types.includes("custom")}
                        style={{ marginRight: "8px" }}
                      />
                      {t("add_event_type_custom")}
                    </label>
                    {haveCustomType && (
                      <input
                        type="text"
                        placeholder={t("add_event_custom_type_placeholder")}
                        value={formValue.custom_type || ""}
                        onChange={(e) =>
                          setFormValue({
                            ...formValue,
                            custom_type: e.target.value,
                          })
                        }
                        className="d-flex w-100 border border-gray-300 rounded py-1 px-2"
                        style={{
                          marginLeft: "10px",
                        }}
                      />
                    )}
                  </div>
                </div>

                <div className="mb-3">
                  <p
                    style={{
                      marginBottom: "0",
                      fontSize: "1.2em",
                      fontFamily: "Kanit-Regular",
                    }}
                  >
                    {t("add_event_files_label")}
                  </p>
                  <div className="mb-2">
                    <CustomFileInput
                      setSelectedFile={(file) =>
                        setFormValue({ ...formValue, image: file })
                      }
                    />
                  </div>
                  <div className="mb-2">
                    <CustomVideoInput
                      setSelectedFile={(file) =>
                        setFormValue({ ...formValue, video: file })
                      }
                    />
                  </div>
                  <div>
                    <CustomAudioInput
                      placeholder={t("add_event_attach_audio")}
                      onlyFile
                      setAudio={(audio) =>
                        setFormValue({ ...formValue, audioOther: audio })
                      }
                    />
                  </div>
                </div>
                <p
                  style={{
                    marginBottom: "0",
                    fontSize: "1.2em",
                  }}
                >
                  {t("add_event_description_label")}
                </p>
                <div>
                  <CustomAudioInput
                    text={formValue.description}
                    setText={(e) =>
                      setFormValue({
                        ...formValue,
                        description: e.target.value,
                      })
                    }
                    placeholder={t("add_event_description_placeholder")}
                    setAudio={(audio) =>
                      setFormValue({ ...formValue, audioDescribe: audio })
                    }
                  />
                </div>
              </div>
            </div>
            <label
              style={{
                fontFamily: "Kanit-Regular",
                display: "flex",
                alignItems: "center",
                margin: "20px 0",
              }}
            >
              <input
                type="checkbox"
                onChange={(e) => {
                  setFormValue({ ...formValue, confirm: e.target.checked });
                }}
                style={{ marginRight: "8px" }}
              />
              <span style={{ color: "red" }}>*</span>
              {t("add_event_confirmation")}
            </label>
            <MDBBtn
              type="submit"
              className="mb-4 w-100 rounded-6"
              size="lg"
              style={{
                backgroundColor: "#B62319",
                borderColor: "#B62319",
                boxShadow: "none",
                fontFamily: "Kanit-Regular",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleSubmit}
            >
              {t("add_event_submit_button")}
            </MDBBtn>
          </MDBCardBody>
        </MDBCard>
        <ModalAlert
          show={modalValue.showModal}
          setShow={(show) => (show ? null : modalValue.onModalClose())}
          title={modalValue.modalTitle}
          onClose={modalValue.onModalClose}
        >
          {modalValue.modalMessage}
        </ModalAlert>
      </MDBContainer>
    </Layout>
  );
};

export default AddEvent;
