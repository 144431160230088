import { useEffect, useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { usePrevious } from "../../libs/hooks";
import { ApiHelper } from "../../libs/api";
import { Layout } from "../../components";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import {
  provinceMapping,
  districtMapping,
  tambonMapping,
} from "../../libs/translations/mappings";

import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBValidation,
  MDBInput,
} from "mdb-react-ui-kit";

const Planning = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addressOptions, setAddressOptions] = useState({
    regions: [],
    provinces: [],
    districts: [],
    tambons: [],
  });

  const [formValue, setFormValue] = useState({
    region: "",
    province: "",
    district: "",
    tambon: "",
    date: dayjs().format("YYYY-MM-DD"),
    time: dayjs().format("HH:mm"),
  });

  const prevFormValue = usePrevious(formValue);

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { region, province, district, tambon, date, time } = formValue;

    const thaiProvince =
      Object.keys(provinceMapping).find(
        (key) => provinceMapping[key] === province
      ) || province;
    const thaiDistrict =
      Object.keys(districtMapping).find(
        (key) => districtMapping[key] === district
      ) || district;
    const thaiTambon =
      Object.keys(tambonMapping).find((key) => tambonMapping[key] === tambon) ||
      tambon;

    if (region && thaiProvince && thaiDistrict && thaiTambon && date && time) {
      navigate({
        pathname: "risk",
        search: createSearchParams({
          region,
          province: thaiProvince,
          district: thaiDistrict,
          tambon: thaiTambon,
          date,
          time,
        }).toString(),
      });
    }
  };

  useEffect(() => {
    const { region, province, district } = formValue;

    if (prevFormValue && prevFormValue.region !== region) {
      if (region) {
        ApiHelper.getProvinces(region).then(({ success, data }) => {
          if (success) {
            setAddressOptions({ ...addressOptions, provinces: data });
          }
        });
      }
      setFormValue({ ...formValue, province: "", district: "", tambon: "" });
    }

    if (prevFormValue && prevFormValue.province !== province) {
      if (province) {
        ApiHelper.getDistricts(province).then(({ success, data }) => {
          if (success) {
            setAddressOptions({ ...addressOptions, districts: data });
          }
        });
      }
      setFormValue({ ...formValue, district: "", tambon: "" });
    }

    if (prevFormValue && prevFormValue.district !== district) {
      if (district) {
        ApiHelper.getTambons(district).then(({ success, data }) => {
          if (success) {
            setAddressOptions({ ...addressOptions, tambons: data });
          }
        });
      }
      setFormValue({ ...formValue, tambon: "" });
    }
  }, [formValue]);

  useEffect(() => {
    ApiHelper.getRegions().then(({ success, data }) => {
      if (success) {
        setAddressOptions({ ...addressOptions, regions: data });
      }
    });
  }, []);

  return (
    <Layout>
      <MDBContainer className="d-flex align-items-center justify-content-center">
        <MDBCard className="mx-4 my-5 rounded-7" style={{ width: "500px" }}>
          <MDBCardBody className="px-3 px-md-5">
            <h2 className="text-uppercase my-2 fw-bold">
              {t("planning_title")}
            </h2>
            <h6 className="mb-4">{t("planning_description")}</h6>
            <MDBValidation onSubmit={handleSubmit}>
              <h5 className="mb-3 fw-bold">{t("location")}</h5>
              <h6 className="mb-1">
                {t("region_label")} <span className="text-danger">*</span>
              </h6>
              <select
                className="form-select mb-4"
                aria-label="input-region"
                id="input-region"
                name="region"
                value={formValue.region}
                onChange={onChange}
                required
              >
                <option value="">{t("select_region")}</option>
                {addressOptions?.regions.map(({ value, label }, idx) => (
                  <option key={`${value}-${idx}`} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <h6 className="mb-1">
                {t("province_label")} <span className="text-danger">*</span>
              </h6>
              <select
                className="form-select mb-4"
                aria-label="input-province"
                id="input-province"
                name="province"
                value={formValue.province}
                onChange={onChange}
                required
                disabled={!formValue.region}
              >
                <option value="">{t("select_province")}</option>
                {addressOptions?.provinces.map(({ value, label }, idx) => (
                  <option key={`${value}-${idx}`} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <h6 className="mb-1">
                {t("district_label")} <span className="text-danger">*</span>
              </h6>
              <select
                className="form-select mb-4"
                aria-label="input-district"
                id="input-district"
                name="district"
                value={formValue.district}
                onChange={onChange}
                required
                disabled={!formValue.province}
              >
                <option value="">{t("select_district")}</option>
                {addressOptions?.districts.map(({ value, label }, idx) => (
                  <option key={`${value}-${idx}`} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <h6 className="mb-1">
                {t("tambon_label")} <span className="text-danger">*</span>
              </h6>
              <select
                className="form-select mb-4"
                aria-label="input-tambon"
                id="input-tambon"
                name="tambon"
                value={formValue.tambon}
                onChange={onChange}
                required
                disabled={!formValue.district}
              >
                <option value="">{t("select_tambon")}</option>
                {addressOptions?.tambons.map(({ value, label }, idx) => (
                  <option key={`${value}-${idx}`} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <h5 className="mb-4 fw-bold">{t("date_time")}</h5>
              <MDBInput
                wrapperClass="mb-4"
                label={t("date_label")}
                size="lg"
                id="input-date"
                type="date"
                name="date"
                value={formValue.date}
                onChange={onChange}
                min={new Date().toISOString().split("T")[0]}
                max={
                  new Date(new Date().setDate(new Date().getDate() + 6))
                    .toISOString()
                    .split("T")[0]
                }
                required
              />
              <MDBInput
                wrapperClass="mb-4"
                label={t("time_label")}
                size="lg"
                id="input-time"
                type="time"
                name="time"
                value={formValue.time}
                onChange={onChange}
                required
              />
              <MDBBtn
                type="submit"
                color="danger"
                className="mb-4 w-100"
                size="lg"
              >
                {t("check_risk")}
              </MDBBtn>
            </MDBValidation>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </Layout>
  );
};

export default Planning;
