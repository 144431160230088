import { useState, useEffect, useContext } from "react";
import { ApiHelper } from "../libs/api";
import { RiskContext } from "../libs/risk";
import { RiskList, RiskSlider, ModalAlert, RiskReport } from ".";
import dayjs from "dayjs";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import { useAuth } from "../auth/AuthContext";
import { GOOGLE_MAPS_API_KEY, USE_GMAP_SDK } from "../libs/constants";
import { useTranslation } from "react-i18next";

import {
  provinceMapping,
  districtMapping,
  tambonMapping,
} from "../libs/translations/mappings";

const isUsingGoogleMaps = USE_GMAP_SDK && Boolean(GOOGLE_MAPS_API_KEY);

const RiskInfo = ({
  province,
  district,
  tambon,
  date,
  time = dayjs().format("HH:mm"),
  isPlanning = false,
}) => {
  const { t, i18n } = useTranslation();
  const { isAuthenticated } = useAuth();
  const user = isAuthenticated;

  const {
    riskInfo,
    setRiskInfo,
    riskReport,
    showModalReport,
    setShowModalReport,
  } = useContext(RiskContext);

  const [position, setPosition] = useState({
    coordinates: [0, 0],
    type: "Point",
  });
  const [location, setLocation] = useState({
    province: "",
    district: "",
    tambon: "",
    curTime: "",
  });
  const [crimeRisk, setCrimeRisk] = useState({
    level: null,
    description: null,
    prob: null,
    interval: null,
  });
  const [riskInterval, setRiskInterval] = useState({});
  const [curSlide, setCurSlide] = useState(date);
  const [riskAdvices, setRiskAdvices] = useState(() => "error");
  const isSmallWidth = window.innerWidth < 576;

  const fetchAdvice = async () => {
    const { success, data } = await ApiHelper.getRiskAdvices();
    if (success) {
      const advices = {};
      const isThai = i18n.language === "th";
      for (const obj of data) {
        advices[obj.risk_type] = {
          general: isThai
            ? obj.general_action.split("\n")
            : obj.general_action_en.split("\n"),
          emergency: isThai
            ? obj.emergency_action.split("\n")
            : obj.emergency_action_en.split("\n"),
        };
      }
      setRiskAdvices(advices);
    }
  };

  useEffect(() => {
    fetchAdvice();
  }, []);

  const handleLocation = async () => {
    let lat = null;
    let long = null;
    if (position?.coordinates) {
      lat = position.coordinates[1];
      long = position.coordinates[0];
    }

    const pv = province ?? location?.province;
    const dt = district ?? location?.district;
    const tb = tambon ?? location?.tambon;
    const d = date ?? dayjs().format("YYYY-MM-DD");
    const t = time ?? dayjs().format("HH:mm");

    const thaiProvince =
      Object.keys(provinceMapping).find((key) => provinceMapping[key] === pv) ||
      pv;
    const thaiDistrict =
      Object.keys(districtMapping).find((key) => districtMapping[key] === dt) ||
      dt;
    const thaiTambon =
      Object.keys(tambonMapping).find((key) => tambonMapping[key] === tb) || tb;

    const { success, data } = await ApiHelper.getCrimeRisk({
      lat: isPlanning ? null : lat,
      long: isPlanning ? null : long,
      province: thaiProvince,
      district: thaiDistrict,
      tambon: thaiTambon,
      date: d,
      time: t,
    });

    if (success && data) {
      setCrimeRisk({
        level: data?.crime_level,
        description: data?.description,
        prob: data?.prob,
        interval: data?.interval,
      });
    }
  };

  useEffect(() => {
    const MAX_DAYS = 7;
    if (crimeRisk.interval) {
      setRiskInterval(crimeRisk.interval);
    } else {
      const risks = {};
      for (let i = 0; i < MAX_DAYS; i++) {
        const d = date ? dayjs(date).add(i, "d") : dayjs().add(i, "d");
        const df = d.format("YYYY-MM-DD");
        risks[df] = {};
        for (let j = 0; j < 24; j++) {
          const t = d.hour(j).minute(0);
          const tf = t.format("HH:mm");
          risks[df][tf] = {
            crime_level: null,
            description: null,
            prob: null,
          };
        }
      }
      setRiskInterval(risks);
    }
    setCurSlide(date || dayjs().format("YYYY-MM-DD"));
  }, [crimeRisk.interval, date, time]);

  const handlePosition = async () => {
    if (
      position?.coordinates?.length === 2 &&
      position.coordinates[0] !== 0 &&
      position.coordinates[1] !== 0
    ) {
      const response_GeoReverse = await ApiHelper.postGeoReverse({
        latitude: position.coordinates[1],
        longitude: position.coordinates[0],
      });
      if (
        response_GeoReverse.success &&
        response_GeoReverse.data.address_components.length > 0
      ) {
        const addressComponents = response_GeoReverse.data.address_components;

        const provinceComponent = addressComponents.find((component) =>
          component.types.includes('administrative_area_level_1')
        );
        const districtComponent = addressComponents.find((component) =>
          component.types.includes('administrative_area_level_2')
        );
        const tambonComponent = addressComponents.find(
          (component) =>
            component.types.includes('sublocality') ||
            component.types.includes('sublocality_level_1')
        );

        const province = provinceComponent?.long_name || '';
        const district = districtComponent?.long_name || '';
        const tambon = tambonComponent?.long_name || '';

        const d = new Date();
        const isThai = i18n.language === 'th';
        const curDate = isThai
          ? d.toLocaleDateString('th-TH', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              weekday: 'short',
            })
          : dayjs(d).format('ddd, MMM D, YYYY');
        const curTime = isThai
          ? d.toLocaleTimeString('th-TH', {
              hour: '2-digit',
              minute: '2-digit',
            })
          : dayjs(d).format('h:mm A');

        setLocation({
          province,
          district,
          tambon,
          curTime: `${curDate} | ${curTime} ${t('time_suffix')}`,
        });
      }
    }
  };

  const handleOSMPosition = async () => {
    const { success, data } = await ApiHelper.getPOI(
      position.coordinates[1],
      position.coordinates[0],
      "jsonv2"
    );
    if (success) {
      const d = new Date();
      const isThai = i18n.language === "th";
      const curDate = isThai
        ? d.toLocaleDateString("th-TH", {
            year: "numeric",
            month: "short",
            day: "numeric",
            weekday: "short",
          })
        : dayjs(d).format("ddd, MMM D, YYYY");
      const curTime = isThai
        ? d.toLocaleTimeString("th-TH", {
            hour: "2-digit",
            minute: "2-digit",
          })
        : dayjs(d).format("h:mm A");

      setLocation({
        province:
          data?.address?.state ??
          data?.address?.city ??
          data?.address?.province,
        district:
          data?.address?.suburb ??
          data?.address?.district ??
          data?.address?.town,
        tambon:
          data?.address?.quarter ??
          data?.address?.municipality ??
          data?.address?.county,
        curTime: `${curDate} | ${curTime} ${t("time_suffix")}`,
      });
    }
  };

  useEffect(() => {
    if (
      user &&
      position.coordinates.length &&
      position.coordinates[0] !== 0 &&
      position.coordinates[1] !== 0
    ) {
      setRiskInfo({
        lat: position.coordinates[1],
        long: position.coordinates[0],
        province: location?.province,
        district: location?.district,
        tambon: location?.tambon,
      });
      handleLocation();
    }
  }, [user, position, province, district, tambon]);

  useEffect(() => {
    if (user) {
      if (tambon) {
        const d = dayjs(`${date} ${time}`).toDate();
        const isThai = i18n.language === 'th';
        const curDate = isThai
          ? d.toLocaleDateString('th-TH', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              weekday: 'short',
            })
          : dayjs(d).format('ddd, MMM D, YYYY');
        const curTime = isThai
          ? d.toLocaleTimeString('th-TH', {
              hour: '2-digit',
              minute: '2-digit',
            })
          : dayjs(d).format('h:mm A');
        setLocation({
          province,
          district,
          tambon,
          curTime: `${curDate} | ${curTime} ${t('time_suffix')}`,
        });
      } else if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setPosition({
              coordinates: [
                position?.coords?.longitude,
                position?.coords?.latitude,
              ],
              type: "Point",
            });
          },
          (error) => {
            setPosition({
              coordinates: [0, 0],
              type: "Point",
            });
          }
        );
      }
    }
  }, [user, province, district, tambon, date, time]);

  useEffect(() => {
    if (
      position?.coordinates?.length === 2 &&
      position.coordinates[0] !== 0 &&
      position.coordinates[1] !== 0
    ) {
      if (user && !tambon) {
        if (isUsingGoogleMaps) {
          handlePosition();
        } else {
          handleOSMPosition();
        }
      }
    }
  }, [user, tambon, position]);

  const NearMeButton = () => {
    return (
      <div className="p-3 mx-2">
        <h5>
          {t("search_safe_area")}{" "}
          {isUsingGoogleMaps ? "Google Maps" : "OpenStreetMap"}
        </h5>
        <div className="row mb-2">
          <div className="col-6">
            <MDBBtn
              type="button"
              className="w-100 h-100 border border-3 rounded-5"
              style={{
                backgroundColor: "#FC9F9F",
                padding: "10px",
                fontSize: "1.1rem",
                color: "black",
              }}
              href={`http://www.google.com/maps/search/"${t(
                "police_station"
              )}"/${position.coordinates[1]},${position.coordinates[0]},17z`}
              target="_blank"
            >
              <MDBIcon className="me-2" fas icon="shield-halved" />
              {t("police_station_near_me")}
            </MDBBtn>
          </div>
          <div className="col-6">
            <MDBBtn
              type="button"
              className="w-100 h-100 border border-3 rounded-5"
              style={{
                backgroundColor: "#66B963",
                padding: "10px",
                fontSize: "1.1rem",
                color: "black",
              }}
              href={`http://www.google.com/maps/search/"${t("hospital")}"/${
                position.coordinates[1]
              },${position.coordinates[0]},17z`}
              target="_blank"
            >
              <MDBIcon className="me-2" fas icon="circle-plus" />
              {t("hospital_near_me")}
            </MDBBtn>
          </div>
        </div>
      </div>
    );
  };

  return user ? (
    <>
      <div className="row mx-0">
        <div className="col-sm-12 col-md-6">
          <div className="p-3 mx-2 align-self-center">
            <h4 className="text-lg md:text-xl lg:text-2xl">
              {location.curTime}
            </h4>
            <h5 className="text-base md:text-lg lg:text-xl">{`${location.district}, ${location.province}`}</h5>

            <div className="my-4">
              <h5 className="text-base md:text-lg lg:text-xl">
                {t("risk_today")}
              </h5>
            </div>

            <RiskSlider
              data={riskInterval[curSlide]}
              date={curSlide}
              curDate={date}
              time={time}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="p-3 mx-2 align-self-center">
            <h4 className="text-lg md:text-xl lg:text-2xl">
              {t("risk_details_next_7_days")}
            </h4>
            <h5 className="text-base md:text-lg lg:text-xl">
              {t("click_to_see_more")}
            </h5>

            <RiskList date={date} data={riskInterval} onClick={setCurSlide} />
          </div>
          <div className="d-none d-md-block">
            <NearMeButton />
          </div>
        </div>
      </div>
      <div className="position-sticky bottom-0 bg-white border-top d-block d-md-none">
        <NearMeButton />
      </div>
      <ModalAlert
        title={t("report_risk")}
        show={showModalReport}
        setShow={(show) => (show ? null : setShowModalReport(false))}
        onClose={() => setShowModalReport(false)}
        size={isSmallWidth ? "sm" : "md"}
      >
        <RiskReport
          date={curSlide}
          time={time}
          provinceData={location.province}
          riskInfo={riskInfo}
          description={riskReport?.description}
          prob={riskReport?.prob}
          level={riskReport?.level}
          advices={riskAdvices}
        />
      </ModalAlert>
    </>
  ) : null;
};

export default RiskInfo;
