import React, { useState } from "react";
import { MDBBtn } from "mdb-react-ui-kit";
import { Layout } from "../../components";
import { ApiHelper } from "../../libs/api";
import { useTranslation } from "react-i18next";

const ChatInterface = () => {
  const { t } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");

  const options = [t("faq_option_1"), t("faq_option_2"), t("faq_option_3")];

  const fetchChatbotResponse = async (question) => {
    const { success, data } = await ApiHelper.getChatbotResponse(question);
    if (success) {
      return data.answer?.[0] || data.answers?.[0] || t("faq_unavailable");
    } else {
      return t("faq_unavailable");
    }
  };

  const handleSendMessage = async (message) => {
    if (message.trim() === "") return;

    setMessages((prevHistory) => [
      ...prevHistory,
      { type: "user", text: message },
    ]);

    try {
      const response = await fetchChatbotResponse(message?.toLowerCase());

      setMessages((prevHistory) => [
        ...prevHistory,
        { type: "bot", text: response },
      ]);
    } catch (error) {
      setMessages((prevHistory) => [
        ...prevHistory,
        { type: "bot", text: t("faq_unavailable") },
      ]);
    }

    setUserInput("");
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      await handleSendMessage(userInput);
    }
  };

  const handleSelect = async (text) => {
    setMessages((prevHistory) => [
      ...prevHistory,
      { type: "user", text: text },
    ]);
    try {
      const response = await fetchChatbotResponse(text);
      setMessages((prevHistory) => [
        ...prevHistory,
        { type: "bot", text: response },
      ]);
    } catch (error) {
      setMessages((prevHistory) => [
        ...prevHistory,
        { type: "bot", text: t("faq_unavailable") },
      ]);
    }
  };

  return (
    <Layout>
      <div
        style={{
          background: "linear-gradient(white, #84BBFF)",
          height: "83.3vh",
          display: "flex",
          flexDirection: "column",
          fontFamily: "Kanit-Regular",
        }}
      >
        <div
          className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
          style={{
            background: "#5E8FCB",
            color: "white",
            padding: "20px",
            textAlign: "left",
            fontFamily: "Kanit-SemiBold",
          }}
        >
          <div>
            <h2
              className="text-uppercase"
              style={{ margin: "0", fontSize: "1.5em" }}
            >
              {t("faq_title")}
            </h2>
            <p style={{ margin: "0", fontSize: "1em" }}>
              {t("faq_description")}
            </p>
          </div>
          <div className="d-flex mt-2 gap-3 align-items-center">
            <button
              style={{
                background: "white",
                color: "#5E8FCB",
                border: "none",
                padding: "5px 10px",
                margin: "0 5px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => window.location.reload()}
            >
              {t("faq_restart")}
              <img
                src="refresh.png"
                alt={t("faq_restart")}
                style={{ width: "16px", marginLeft: "5px" }}
              />
            </button>
            <button
              style={{
                background: "white",
                color: "#5E8FCB",
                border: "none",
                padding: "5px 10px",
                margin: "0 5px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {t("faq_more_info")}
              <img
                src="info.png"
                alt={t("faq_more_info")}
                style={{ width: "16px", marginLeft: "5px" }}
              />
            </button>
          </div>
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            padding: "20px",
            boxSizing: "border-box",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              flex: 1,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              padding: "10px",
              boxSizing: "border-box",
              maxHeight: "calc(100vh - 180px)",
            }}
          >
            {messages.map((msg, index) => (
              <div
                key={`${msg.type}_${index}`}
                style={{
                  marginBottom: "10px",
                  padding: "10px",
                  borderRadius: "10px",
                  background: msg.type === "user" ? "#5E8FCB" : "white",
                  color: msg.type === "user" ? "white" : "black",
                  alignSelf: msg.type === "user" ? "flex-end" : "flex-start",
                  textAlign: msg.type === "user" ? "right" : "left",
                  width: "fit-content",
                  maxWidth: "60%",
                  wordWrap: "break-word",
                  boxSizing: "border-box",
                  marginLeft: msg.type === "user" ? "auto" : "0",
                  marginRight: msg.type === "user" ? "0" : "auto",
                }}
              >
                {msg.text}
              </div>
            ))}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: "10px",
                padding: "10px",
                border: "2px dashed white",
                borderRadius: "10px",
                maxWidth: "60%",
                marginLeft: "auto",
                marginBottom: "30px",
                backgroundColor: "#5E8FCB80",
              }}
            >
              <span style={{ color: "white" }}>{t("faq_quick_reply")}</span>
              <div className="d-flex flex-column flex-sm-row gap-2">
                {options.map((item, index) => (
                  <MDBBtn
                    key={`faq-choice-${index}`}
                    style={{ backgroundColor: "#5E8FCB", color: "white" }}
                    onClick={() => handleSelect(item)}
                  >
                    {item}
                  </MDBBtn>
                ))}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "100%",
              padding: "0 20px",
              position: "fixed",
              bottom: "40px",
              left: "0",
              backgroundColor: "#5E8FCB",
              height: "auto",
              maxHeight: "80px",
              zIndex: 1000,
            }}
          >
            <div
              style={{ position: "relative", width: "80%", margin: "0 auto" }}
            >
              <textarea
                placeholder={t("faq_input_placeholder")}
                value={userInput}
                onKeyDown={handleKeyPress}
                onChange={(e) => setUserInput(e.target.value)}
                style={{
                  width: "100%",
                  minHeight: "40px",
                  maxHeight: "50px",
                  backgroundColor: "#DDECFF",
                  border: "1px solid #CED4DA",
                  borderRadius: "5px",
                  padding: "10px",
                  paddingRight: "40px",
                  boxSizing: "border-box",
                  overflow: "hidden",
                  resize: "none",
                  outline: "none",
                }}
              />
              <button
                onClick={() => handleSendMessage(userInput)}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <img
                  src="send.png"
                  alt={t("faq_send")}
                  style={{ width: "20px", height: "20px" }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ChatInterface;
